import React from "react";
import {LocaleContext} from "../layout";
import FooterCategorySPJA from "./FooterCategorySPJA";
import FooterCategorySPEN from "./FooterCategorySPEN";

const FooterCategorySP = () => {
    const {locale} = React.useContext(LocaleContext);
    return (
        <>
            {locale === 'ja' ? <FooterCategorySPJA/> : <FooterCategorySPEN/>}
        </>
    );
};

export default FooterCategorySP;