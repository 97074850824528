import React from "react";
import FooterCategoryPCJA from "./FooterCategoryPCJA";
import FooterCategoryPCEN from "./FooterCategoryPCEN";
import {LocaleContext} from "../layout";

const FooterCategoryPC = () => {
    const {locale} = React.useContext(LocaleContext);
    return (
        <>
            {locale === 'ja' ? <FooterCategoryPCJA/> : <FooterCategoryPCEN/>}
        </>
    );
};

export default FooterCategoryPC;