import React from "react";
import LocalizedLink from "../localizedLink";
import useTranslationsCat from "../useTranslationsCat";
import Styles from "./Footer.module.scss";

const FooterCategoryPC = () => {
const {
thermometers,
digitalThermometers,
dataloggersTemperature,
analogThermometers,
thermographs,
remoteSensingDialThermometers,
rsdOrder,
rsdSp,
rsdWl,
rsdFl,
rsdSt,
rsdWp,
wpWl,
wpFl,
wpS,
wpT,
rsdO,
oWl,
oFl,
oS,
oT,
rsdEc,
ecWl,
ecFl,
ecSt,
ecWp,
ecRu,
rsdRc,
rsdDc,
bimetalThermometers,
bmOrder,
bmSp,
bmT,
bmS,
bmF,
bmR,
bmEc,
bmWp,
bmDc,
standardGlassThermometers,
glassThermometers,
thermocouples,
resistanceTemperatureDetectors,
thermohygrometers,
digitalThermohygrometers,
dataloggersTemperatureHumidity,
transmitters,
dialThermohygrometers,
thermohygrographs,
psychrometers,
anemometers,
heatStressMonitors,
infraredThermometers,
barometers,
digitalBarometer,
dialBarometers,
barograph,
recorders,
indicators,
weatherInstruments,
windSpeedDirectionIndicator,
anemometersWeatherInstruments,
rainGauge,
barometersWeatherInstruments,
loggers,
instrumentScreens,
moistureMeters,
soilPhMetersSaltMetersCo2MetersOtheres,
refractometers,
phMeters,
timers,
timersSecondLayer,
stopwatches,
sandglasses,
optionalProbes,
forSkL751,
forSkL753,
forSkL754,
forSkL700rT,
forSkL700rTh,
forSkL200TIISeries,
forSkL200th2aSeries,
forSkL400tSk1110Sk1120Sk7000PRTII,
forSk1260,
forSk1250MCIIISk1250MCIIIa,
forSk270wpSeries,
forSk250wp2Series,
forSk100wp,
forSk810pt,
forSk110trhB,
forSkRhcSeries,
forSkM460TSkM350T,
forSkM350rT,
forSkM350rTrh,
forSk610ph2,
forSk660phSeries,
forSk620ph2Sk650ph,
forSk620ph,
forThermocouple,
chartsAndPens,
forSigma2Thermohygrographs,
forAurora903Thermohygrograph,
forSigma2Thermograph,
forSigma2Hygrograph,
forSigma2Barograph,
forSigma2RemoteThermographs,
forMiniCubeThermohygrograph,
forMiniStarThermohygrograph,
forMiniAlphaThermohygrograph,
forEventRecorder,
forLmmcTemperatureRecorder,
forVariousRecorders,
cartridgePens,
otherAccessories,
seriesProducts,
seriesSk270wp,
seriesSk1260,
seriesSk110trhb,
seriesSk630ph,
seriesSk660ph,
seriesSk960a,
seriesBms90s,
seriesBmt90s,
seriesBmt75s,
seriesBms75p,
seriesBmt75p,
seriesBms100p,
seriesBmt100p,
seriesProtectorPipeForBimetal,
seriesProtectorPipeForRemoteSensing,
seriesLb100s150s,
seriesVb100p,
seriesYoungAnemometer,
seriesHandheldAnemometer,
seriesWvat6d0,
seriesWat6d0,
seriesRa001,
seriesIndicatorTemp,
seriesIndicatorHum,
seriesSkrsd10,
seriesSkrm10c,
seriesSkrm18j,
seriesSurfaceBimetal,
seriesPocketBimetal,
seriesAsphaltBimetal,
seriesMiluno,
seriesMilunotz,
seriesWoodenzBase,
seriesWoodenzBase2,
seriesSoilAir,
seriesCheckermate,
seriesSigma2HvrCharts,
seriesSigmaminiCharts,
seriesSigma2OtherAccessories,
seriesSkrhgOption,
seriesCalibrationSolution,
seriesSensorFilters,
seriesTm24p,
seriesBabymate,
traceabilityProducts,
discontinuedProducts,
} = useTranslationsCat();
return(
<ul className={Styles.footercategory}>
<li>
<input type="checkbox" id="footer-thermometers" className={Styles.acInput}/>
<label htmlFor="footer-thermometers" className={Styles.acLabel} >{thermometers}</label>
<ul className={Styles.footercategory2}>
<li>
<LocalizedLink to="/categories/thermometers/digital-thermometers/"  className="init-right line">{digitalThermometers}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/thermometers/dataloggers-temperature/"  className="init-right line">{dataloggersTemperature}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/thermometers/analog-thermometers/"  className="init-right line">{analogThermometers}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/thermometers/thermographs/"  className="init-right line">{thermographs}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/thermometers/remote-sensing-dial-thermometers/"  className="init-right line">{remoteSensingDialThermometers}</LocalizedLink>
</li>
<li>
<input type="checkbox" id="footer-rsd-order" className={Styles.acInput}/>
<label htmlFor="footer-rsd-order" className={Styles.acLabel} >{rsdOrder}</label>
<ul className={Styles.footercategory2}>
<li>
<input type="checkbox" id="footer-rsd-sp" className={Styles.acInput}/>
<label htmlFor="footer-rsd-sp" className={Styles.acLabel} >{rsdSp}</label>
<ul className={Styles.footercategory2}>
<li>
<LocalizedLink to="/categories/thermometers/rsd-order/rsd-sp/rsd-wl/"  className="init-right line">{rsdWl}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/thermometers/rsd-order/rsd-sp/rsd-fl/"  className="init-right line">{rsdFl}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/thermometers/rsd-order/rsd-sp/rsd-st/"  className="init-right line">{rsdSt}</LocalizedLink>
</li>

</ul>
</li>
<li>
<input type="checkbox" id="footer-rsd-wp" className={Styles.acInput}/>
<label htmlFor="footer-rsd-wp" className={Styles.acLabel} >{rsdWp}</label>
<ul className={Styles.footercategory2}>
<li>
<LocalizedLink to="/categories/thermometers/rsd-order/rsd-wp/wp-wl/"  className="init-right line">{wpWl}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/thermometers/rsd-order/rsd-wp/wp-fl/"  className="init-right line">{wpFl}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/thermometers/rsd-order/rsd-wp/wp-s/"  className="init-right line">{wpS}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/thermometers/rsd-order/rsd-wp/wp-t/"  className="init-right line">{wpT}</LocalizedLink>
</li>

</ul>
</li>
<li>
<input type="checkbox" id="footer-rsd-o" className={Styles.acInput}/>
<label htmlFor="footer-rsd-o" className={Styles.acLabel} >{rsdO}</label>
<ul className={Styles.footercategory2}>
<li>
<LocalizedLink to="/categories/thermometers/rsd-order/rsd-o/o-wl/"  className="init-right line">{oWl}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/thermometers/rsd-order/rsd-o/o-fl/"  className="init-right line">{oFl}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/thermometers/rsd-order/rsd-o/o-s/"  className="init-right line">{oS}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/thermometers/rsd-order/rsd-o/o-t/"  className="init-right line">{oT}</LocalizedLink>
</li>

</ul>
</li>
<li>
<input type="checkbox" id="footer-rsd-ec" className={Styles.acInput}/>
<label htmlFor="footer-rsd-ec" className={Styles.acLabel} >{rsdEc}</label>
<ul className={Styles.footercategory2}>
<li>
<LocalizedLink to="/categories/thermometers/rsd-order/rsd-ec/ec-wl/"  className="init-right line">{ecWl}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/thermometers/rsd-order/rsd-ec/ec-fl/"  className="init-right line">{ecFl}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/thermometers/rsd-order/rsd-ec/ec-st/"  className="init-right line">{ecSt}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/thermometers/rsd-order/rsd-ec/ec-wp/"  className="init-right line">{ecWp}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/thermometers/rsd-order/rsd-ec/ec-ru/"  className="init-right line">{ecRu}</LocalizedLink>
</li>

</ul>
</li>
<li>
<LocalizedLink to="/categories/thermometers/rsd-order/rsd-rc/"  className="init-right line">{rsdRc}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/thermometers/rsd-order/rsd-dc/"  className="init-right line">{rsdDc}</LocalizedLink>
</li>

</ul>
</li>
<li>
<LocalizedLink to="/categories/thermometers/bimetal-thermometers/"  className="init-right line">{bimetalThermometers}</LocalizedLink>
</li>
<li>
<input type="checkbox" id="footer-bm-order" className={Styles.acInput}/>
<label htmlFor="footer-bm-order" className={Styles.acLabel} >{bmOrder}</label>
<ul className={Styles.footercategory2}>
<li>
<input type="checkbox" id="footer-bm-sp" className={Styles.acInput}/>
<label htmlFor="footer-bm-sp" className={Styles.acLabel} >{bmSp}</label>
<ul className={Styles.footercategory2}>
<li>
<LocalizedLink to="/categories/thermometers/bm-order/bm-sp/bm-t/"  className="init-right line">{bmT}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/thermometers/bm-order/bm-sp/bm-s/"  className="init-right line">{bmS}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/thermometers/bm-order/bm-sp/bm-f/"  className="init-right line">{bmF}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/thermometers/bm-order/bm-sp/bm-r/"  className="init-right line">{bmR}</LocalizedLink>
</li>

</ul>
</li>
<li>
<LocalizedLink to="/categories/thermometers/bm-order/bm-ec/"  className="init-right line">{bmEc}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/thermometers/bm-order/bm-wp/"  className="init-right line">{bmWp}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/thermometers/bm-order/bm-dc/"  className="init-right line">{bmDc}</LocalizedLink>
</li>

</ul>
</li>
<li>
<LocalizedLink to="/categories/thermometers/standard-glass-thermometers/"  className="init-right line">{standardGlassThermometers}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/thermometers/glass-thermometers/"  className="init-right line">{glassThermometers}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/thermometers/thermocouples/"  className="init-right line">{thermocouples}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/thermometers/resistance-temperature-detectors/"  className="init-right line">{resistanceTemperatureDetectors}</LocalizedLink>
</li>

</ul>
</li>
<li>
<input type="checkbox" id="footer-thermohygrometers" className={Styles.acInput}/>
<label htmlFor="footer-thermohygrometers" className={Styles.acLabel} >{thermohygrometers}</label>
<ul className={Styles.footercategory2}>
<li>
<LocalizedLink to="/categories/thermohygrometers/digital-thermohygrometers/"  className="init-right line">{digitalThermohygrometers}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/thermohygrometers/dataloggers-temperature-Humidity/"  className="init-right line">{dataloggersTemperatureHumidity}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/thermohygrometers/transmitters/"  className="init-right line">{transmitters}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/thermohygrometers/dial-thermohygrometers/"  className="init-right line">{dialThermohygrometers}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/thermohygrometers/thermohygrographs/"  className="init-right line">{thermohygrographs}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/thermohygrometers/psychrometers/"  className="init-right line">{psychrometers}</LocalizedLink>
</li>

</ul>
</li>
<li>
<LocalizedLink to="/categories/anemometers/" className="init-right line">{anemometers}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/heat-stress-monitors/" className="init-right line">{heatStressMonitors}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/infrared-thermometers/" className="init-right line">{infraredThermometers}</LocalizedLink>
</li>
<li>
<input type="checkbox" id="footer-barometers" className={Styles.acInput}/>
<label htmlFor="footer-barometers" className={Styles.acLabel} >{barometers}</label>
<ul className={Styles.footercategory2}>
<li>
<LocalizedLink to="/categories/barometers/digital-barometer/"  className="init-right line">{digitalBarometer}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/barometers/dial-barometers/"  className="init-right line">{dialBarometers}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/barometers/barograph/"  className="init-right line">{barograph}</LocalizedLink>
</li>

</ul>
</li>
<li>
<LocalizedLink to="/categories/recorders/" className="init-right line">{recorders}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/indicators/" className="init-right line">{indicators}</LocalizedLink>
</li>
<li>
<input type="checkbox" id="footer-weather-instruments" className={Styles.acInput}/>
<label htmlFor="footer-weather-instruments" className={Styles.acLabel} >{weatherInstruments}</label>
<ul className={Styles.footercategory2}>
<li>
<LocalizedLink to="/categories/weather-instruments/wind-speed-direction-indicator/"  className="init-right line">{windSpeedDirectionIndicator}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/weather-instruments/anemometers-weather-instruments/"  className="init-right line">{anemometersWeatherInstruments}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/weather-instruments/rain-gauge/"  className="init-right line">{rainGauge}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/weather-instruments/barometers-weather-instruments/"  className="init-right line">{barometersWeatherInstruments}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/weather-instruments/loggers/"  className="init-right line">{loggers}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/weather-instruments/instrument-screens/"  className="init-right line">{instrumentScreens}</LocalizedLink>
</li>

</ul>
</li>
<li>
<LocalizedLink to="/categories/moisture-meters/" className="init-right line">{moistureMeters}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/soil-ph-meters-salt-meters-co2-meters-otheres/" className="init-right line">{soilPhMetersSaltMetersCo2MetersOtheres}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/refractometers/" className="init-right line">{refractometers}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/ph-meters/" className="init-right line">{phMeters}</LocalizedLink>
</li>
<li>
<input type="checkbox" id="footer-timers" className={Styles.acInput}/>
<label htmlFor="footer-timers" className={Styles.acLabel} >{timers}</label>
<ul className={Styles.footercategory2}>
<li>
<LocalizedLink to="/categories/timers/timers-second-layer/"  className="init-right line">{timersSecondLayer}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/timers/stopwatches/"  className="init-right line">{stopwatches}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/timers/sandglasses/"  className="init-right line">{sandglasses}</LocalizedLink>
</li>

</ul>
</li>
<li>
<input type="checkbox" id="footer-optional-probes" className={Styles.acInput}/>
<label htmlFor="footer-optional-probes" className={Styles.acLabel} >{optionalProbes}</label>
<ul className={Styles.footercategory2}>
<li>
<LocalizedLink to="/categories/optional-probes/for-sk-l751/"  className="init-right line">{forSkL751}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/optional-probes/for-sk-l753/"  className="init-right line">{forSkL753}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/optional-probes/for-sk-l754/"  className="init-right line">{forSkL754}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/optional-probes/for-sk-l700r-t/"  className="init-right line">{forSkL700rT}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/optional-probes/for-sk-l700r-th/"  className="init-right line">{forSkL700rTh}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/optional-probes/for-sk-l200TII-Series/"  className="init-right line">{forSkL200TIISeries}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/optional-probes/for-sk-l200th2a-series/"  className="init-right line">{forSkL200th2aSeries}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/optional-probes/for-sk-l400t-sk-1110-sk-1120-sk-7000PRTII/"  className="init-right line">{forSkL400tSk1110Sk1120Sk7000PRTII}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/optional-probes/for-sk-1260/"  className="init-right line">{forSk1260}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/optional-probes/for-sk-1250MCIII-sk-1250MCIIIa/"  className="init-right line">{forSk1250MCIIISk1250MCIIIa}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/optional-probes/for-sk-270wp-series/"  className="init-right line">{forSk270wpSeries}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/optional-probes/for-sk-250wp2-series/"  className="init-right line">{forSk250wp2Series}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/optional-probes/for-sk-100wp/"  className="init-right line">{forSk100wp}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/optional-probes/for-sk-810pt/"  className="init-right line">{forSk810pt}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/optional-probes/for-sk-110trh-b/"  className="init-right line">{forSk110trhB}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/optional-probes/for-sk-rhc-series/"  className="init-right line">{forSkRhcSeries}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/optional-probes/for-sk-m460-t-sk-m350-t/"  className="init-right line">{forSkM460TSkM350T}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/optional-probes/for-sk-m350r-t/"  className="init-right line">{forSkM350rT}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/optional-probes/for-sk-m350r-trh/"  className="init-right line">{forSkM350rTrh}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/optional-probes/for-sk-610ph-2/"  className="init-right line">{forSk610ph2}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/optional-probes/for-sk-660ph-series/"  className="init-right line">{forSk660phSeries}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/optional-probes/for-sk-620ph2-sk-650ph/"  className="init-right line">{forSk620ph2Sk650ph}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/optional-probes/for-sk-620ph/"  className="init-right line">{forSk620ph}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/optional-probes/for-thermocouple/"  className="init-right line">{forThermocouple}</LocalizedLink>
</li>

</ul>
</li>
<li>
<input type="checkbox" id="footer-charts-and-pens" className={Styles.acInput}/>
<label htmlFor="footer-charts-and-pens" className={Styles.acLabel} >{chartsAndPens}</label>
<ul className={Styles.footercategory2}>
<li>
<LocalizedLink to="/categories/charts-and-pens/for-sigma2-thermohygrographs/"  className="init-right line">{forSigma2Thermohygrographs}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/charts-and-pens/for-aurora90-3-thermohygrograph/"  className="init-right line">{forAurora903Thermohygrograph}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/charts-and-pens/for-sigma2-thermograph/"  className="init-right line">{forSigma2Thermograph}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/charts-and-pens/for-sigma2-hygrograph/"  className="init-right line">{forSigma2Hygrograph}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/charts-and-pens/for-sigma2-barograph/"  className="init-right line">{forSigma2Barograph}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/charts-and-pens/for-sigma2-remote-thermographs/"  className="init-right line">{forSigma2RemoteThermographs}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/charts-and-pens/for-mini-cube-thermohygrograph/"  className="init-right line">{forMiniCubeThermohygrograph}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/charts-and-pens/for-mini-star-thermohygrograph/"  className="init-right line">{forMiniStarThermohygrograph}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/charts-and-pens/for-mini-alpha-thermohygrograph/"  className="init-right line">{forMiniAlphaThermohygrograph}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/charts-and-pens/for-event-recorder/"  className="init-right line">{forEventRecorder}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/charts-and-pens/for-lmmc-temperature-recorder/"  className="init-right line">{forLmmcTemperatureRecorder}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/charts-and-pens/for-various-recorders/"  className="init-right line">{forVariousRecorders}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/charts-and-pens/cartridge-pens/"  className="init-right line">{cartridgePens}</LocalizedLink>
</li>

</ul>
</li>
<li>
<LocalizedLink to="/categories/other-accessories/" className="init-right line">{otherAccessories}</LocalizedLink>
</li>
<li>
<input type="checkbox" id="footer-series-products" className={Styles.acInput}/>
<label htmlFor="footer-series-products" className={Styles.acLabel} >{seriesProducts}</label>
<ul className={Styles.footercategory2}>
<li>
<LocalizedLink to="/categories/series-products/series-sk270wp/"  className="init-right line">{seriesSk270wp}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/series-products/series-sk1260/"  className="init-right line">{seriesSk1260}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/series-products/series-sk110trhb/"  className="init-right line">{seriesSk110trhb}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/series-products/series-sk630ph/"  className="init-right line">{seriesSk630ph}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/series-products/series-sk660ph/"  className="init-right line">{seriesSk660ph}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/series-products/series-sk960a/"  className="init-right line">{seriesSk960a}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/series-products/series-bms90s/"  className="init-right line">{seriesBms90s}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/series-products/series-bmt90s/"  className="init-right line">{seriesBmt90s}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/series-products/series-bmt75s/"  className="init-right line">{seriesBmt75s}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/series-products/series-bms75p/"  className="init-right line">{seriesBms75p}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/series-products/series-bmt75p/"  className="init-right line">{seriesBmt75p}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/series-products/series-bms100p/"  className="init-right line">{seriesBms100p}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/series-products/series-bmt100p/"  className="init-right line">{seriesBmt100p}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/series-products/series-protector-pipe-for-bimetal/"  className="init-right line">{seriesProtectorPipeForBimetal}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/series-products/series-protector-pipe-for-remote-sensing/"  className="init-right line">{seriesProtectorPipeForRemoteSensing}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/series-products/series-lb100s-150s/"  className="init-right line">{seriesLb100s150s}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/series-products/series-vb100p/"  className="init-right line">{seriesVb100p}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/series-products/series-young-anemometer/"  className="init-right line">{seriesYoungAnemometer}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/series-products/series-handheld-anemometer/"  className="init-right line">{seriesHandheldAnemometer}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/series-products/series-wvat6d0/"  className="init-right line">{seriesWvat6d0}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/series-products/series-wat6d0/"  className="init-right line">{seriesWat6d0}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/series-products/series-ra001/"  className="init-right line">{seriesRa001}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/series-products/series-indicator-temp/"  className="init-right line">{seriesIndicatorTemp}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/series-products/series-indicator-hum/"  className="init-right line">{seriesIndicatorHum}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/series-products/series-skrsd10/"  className="init-right line">{seriesSkrsd10}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/series-products/series-skrm10c/"  className="init-right line">{seriesSkrm10c}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/series-products/series-skrm18j/"  className="init-right line">{seriesSkrm18j}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/series-products/series-surface-bimetal/"  className="init-right line">{seriesSurfaceBimetal}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/series-products/series-pocket-bimetal/"  className="init-right line">{seriesPocketBimetal}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/series-products/series-asphalt-bimetal/"  className="init-right line">{seriesAsphaltBimetal}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/series-products/series-miluno/"  className="init-right line">{seriesMiluno}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/series-products/series-milunotz/"  className="init-right line">{seriesMilunotz}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/series-products/series-woodenz-base/"  className="init-right line">{seriesWoodenzBase}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/series-products/series-woodenz-base2/"  className="init-right line">{seriesWoodenzBase2}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/series-products/series-soil-air/"  className="init-right line">{seriesSoilAir}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/series-products/series-checkermate/"  className="init-right line">{seriesCheckermate}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/series-products/series-sigma2-hvr-charts/"  className="init-right line">{seriesSigma2HvrCharts}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/series-products/series-sigmamini-charts/"  className="init-right line">{seriesSigmaminiCharts}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/series-products/series-sigma2-other-accessories/"  className="init-right line">{seriesSigma2OtherAccessories}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/series-products/series-skrhg-option/"  className="init-right line">{seriesSkrhgOption}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/series-products/series-calibration-solution/"  className="init-right line">{seriesCalibrationSolution}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/series-products/series-sensor-filters/"  className="init-right line">{seriesSensorFilters}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/series-products/series-tm24p/"  className="init-right line">{seriesTm24p}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/series-products/series-babymate/"  className="init-right line">{seriesBabymate}</LocalizedLink>
</li>

</ul>
</li>
<li>
<LocalizedLink to="/categories/traceability-products/" className="init-right line">{traceabilityProducts}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/discontinued-products/" className="init-right line">{discontinuedProducts}</LocalizedLink>
</li>
</ul>
);
};

export default FooterCategoryPC;